@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 100 900;
  src: url("./RubikVariableFontWeight.ttf") format("truetype-variations");
}

@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 100 900;
  src: url("./RubikItalicVariableFontWeight.ttf") format("truetype-variations");
}
